import React, { useEffect, useState } from "react";
// import './CouponList.css';
import Sidebar from "../../components/Sidebar/index";
import Topbar from "../../components/Topbar/index";
import { Table, thead, Tbody, th, tr, td } from 'react-super-responsive-table';
import { Link, useParams } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DatePicker from "react-datepicker";
import { display } from "@mui/system";
import { displayError, displaySuccess, getCouponDetail } from "../../Utils/utils";
import axios from 'axios';
import config from "../../config/config";
import { useTranslation } from "react-i18next";

const EditDiscount = () => {
	const [startdate, setStartDate] = useState(new Date());
	const [couponcode, setCouponCode] = useState('');
	const [discountpercentage, setDiscountPercentage] = useState('');
	const [value, setValue] = useState('');
	const [quantity, setQuantity] = useState('');
	const [enddate, setEndDate] = useState(new Date());
	const { id } = useParams();
	const [type, setType] = useState('');
	const [is_disable, setIsDisable] = useState(false);
	const [isPercentDisable, setIsPercentDisable] = useState(false);
	const { t } = useTranslation()

	const onEditDiscount = async (id) => {
		var token = localStorage.getItem('token');
		let path = config.siteUrl;
		await axios.post(path + '/auth/admin/coupon/update', {
			start_date: startdate,
			code: couponcode,
			percentage: discountpercentage,
			end_date: enddate,
			amount: value,
			quantity: quantity,
			type: type,
			coupon_id: id
		}, {
			headers: { 'content-Type': 'application/json', Authorization: `Bearer ${token}` },
		})
			.then((response) => {
				displaySuccess(t('Coupon Updated SuccessFully'));
				setTimeout(() => window.location.href = "/discount-coupon", 3000);
			})
			.catch((error) => {
				displayError(error, t);
			});
	};
	const getDetail = async (id) => {
		var res = await getCouponDetail(id)
		if (res !== null) {
			setCouponCode(res.code)
			setQuantity(res.quantity)
			setDiscountPercentage(res.percentage)
			setStartDate(new Date(res.start_date));
			setEndDate(new Date(res.end_date));
			setValue(res.amount)
			setType(res.type)
			if (res.type === "amount") {
				setIsPercentDisable(true)
			} else {
				setIsDisable(true)
			}
		}
	}
	useEffect(() => {
		getDetail(id)
	}, [])
	return (
		<>
			<div id="wrapper">
				<Sidebar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<Topbar title={t('Edit Coupon')} />
						<div className="container-fluid">
							<div className="col-xl-12">
								<div className="card border-radius-10 shadow h-100 py-2" style={{ borderRadius: '15px' }}>
									<div className="card-body">
										<div className="row">
											{/* <h5 className="heading1 mb-0">Recent Deals List</h5> */}
											<div className="btntop">
												<Link to="/discount-coupon">
													<button className="buttontop mr-5">
														<i class="fas fa-long-arrow-alt-left"></i> {t('Back')}</button>
												</Link>
											</div>
										</div>
										<div className=" mb-4 mt-3">
											<div className="col-md-12">
												<div className="card m-overflow-h" style={{ border: "none" }}>
													<div className="card-body m-p-0" >
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Code')}</span>
																	<input type="text" className="form-control mt-2" placeholder={t("Code")} onChange={e => setCouponCode(e.target.value)} value={couponcode} />
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t("Discount Percentage")}</span>
																	<input type="text" className="form-control mt-2" placeholder={t("Discount Percentage")}
																		onChange={e => {
																			setType('percentage')
																			setDiscountPercentage(e.target.value)
																			if (e.target.value.length > 0) {
																				setIsDisable(true)
																			} else {
																				setIsDisable(false)
																			}
																		}}
																		disabled={isPercentDisable}
																		value={discountpercentage} />
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Coupon Value')}</span>
																	<input type="text" className="form-control mt-2" placeholder={t("Coupon Value")}
																		onChange={e => {
																			setType('amount')
																			setValue(e.target.value)
																			if (e.target.value.length > 0) {
																				setIsPercentDisable(true)
																			} else {
																				setIsPercentDisable(false)
																			}
																		}}
																		disabled={is_disable} value={value} />
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Quantity Limit Actions')} </span>
																	<input type="number" className="form-control mt-2" placeholder={t("Quantity Limit Actions")}
																		onChange={e => setQuantity(e.target.value)} value={quantity} />
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Start Date')}</span><br />
																	<DatePicker className="form-control mt-2"
																		selected={startdate}
																		onChange={(date) => setStartDate(date)}
																		// value={startdate}
																		customInput={
																			<input
																				style={{ width: '360px' }}
																				type="text"
																				className="form-control"
																				placeholder={('Date')}
																				value={startdate}
																			/>
																		}
																	/>
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('End Date')}</span><br />
																	<DatePicker className="form-control mt-2"
																		selected={enddate}
																		onChange={(date) => setEndDate(date)}
																		// value={enddate}
																		customInput={
																			<input
																				style={{ width: '360px' }}
																				type="text"
																				className="form-control"
																				placeholder={('Date')}
																				value={enddate}
																			/>
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
													{/* <Link to=""> */}
													<button type="button" className="btn bg-warning text-white ml-3"
														style={{ width: '20%' }}
														onClick={() => onEditDiscount(id)}>{t('Submit')}</button>
													{/* </Link> */}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
}
export default EditDiscount;
