import React, { useEffect, useState } from "react";
import './DiscountCoupon.css';
import Sidebar from "../components/Sidebar/index";
import Topbar from "../components/Topbar/index";
import { Table, thead, Tbody, th, tr, td } from 'react-super-responsive-table';
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { displaySuccess, getCouponList, displayError } from "../Utils/utils";
import config from "../config/config";
import axios from "axios";
import { useTranslation } from "react-i18next";

const DiscountCoupon = (props) => {
	const [is_delete, setIsDelete] = useState(false);
	const [coupons, setCoupons] = useState([]);
	const [temp_id, setTempId] = useState('');
	const { t } = useTranslation()
	const getCoupons = async () => {
		var res = await getCouponList()
		setCoupons(res)
	}

	useEffect(() => {
		getCoupons()
	}, [])
	const deleteCoupon = async (id) => {
		var token = localStorage.getItem('token');
		let path = config.siteUrl;
		axios.post(path + "/auth/admin/coupon/delete/" + id, {
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
		})
			.then(async (response) => {
				displaySuccess(t('Deleted Successfully'));
				getCoupons()
			})
			.catch(function (error) {
				displayError(error, t);
			});
	}

	return (
		<>
			<div id="wrapper">
				<Sidebar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<Topbar title={t('Discount Coupon')} />
						<div className="container-fluid">
							<div className="col-xl-12">
								<div className="card border-radius-10 shadow h-100 py-2" style={{ borderRadius: '15px' }}>
									<div className="card-body">
										<div className="row">
											<h5 className="heading1 mb-0">{t('Recent Discount Coupon')}</h5>
											<div className="btntop">
												<Link to="/add-coupon">
													<button className="buttontop mr-5">
														<i class="fas fa-user-plus text-white mr-2 text" ></i>{t('Add Coupon')}</button>
												</Link>
											</div>
										</div>
										<div className=" mb-4 mt-3">
											<div className="col-md-12">
												<div className="card m-overflow-h" style={{ border: "none" }}>

													<div className="card-body m-p-0" >
														<div className="table-responsive">
															<Table className="table align-items-center" >
																<thead>
																	<th>{t('Coupon Code')}</th>
																	<th>{t('Discount Percentage')}</th>
																	<th>{t('Coupon Value')}</th>
																	<th>{t('Quantity Limit Actions')}</th>
																	<th>{t('Start Date')}</th>
																	<th>{t('End Date')}</th>
																	<th>{t('Actions')}</th>
																</thead>
																<tbody>
																	{coupons && coupons.map((list) => (

																		<tr>
																			<td className="tabledata">{list.code}</td>
																			<td>{list.percentage}</td>
																			<td >{list.amount}</td>
																			<td>{list.quantity}</td>
																			<td>{list.start_date}</td>
																			<td>{list.end_date}</td>
																			<td>
																				<div className="row">
																					<Link to={"/edit-coupon/" + list.id}>
																						<div className="items-center mr-1 btn-outline-secondary btn btn-sm" href>
																							<i class="far fa-edit"></i>
																						</div>
																					</Link>
																					<div className="items-center mr-1 btn-outline-danger btn btn-sm"
																						onClick={() => {
																							setIsDelete(true)
																							setTempId(list.id)
																						}}><i class="far fa-trash-alt" ></i>
																					</div>
																				</div>
																			</td>
																		</tr>
																	))}

																	{/*  */}

																</tbody>
															</Table>
														</div>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{is_delete === true ? (
				<SweetAlert
					warning
					showCancel
					cancelBtnText={t("Cancel")}
					confirmBtnText={t("Yes")}
					confirmBtnBsStyle="danger"
					title={t('Are you sure?')}
					onConfirm={(e) => {
						deleteCoupon(temp_id)
						setIsDelete(false)
						// window.location.reload(false)
					}}
					onCancel={(e) => {
						setIsDelete(false)
					}}
					focusCancelBtn
				>
					{t('Do you want to delete this Coupon')}?
				</SweetAlert>
			) : ([]
			)}
		</>
	);
}
export default DiscountCoupon;