import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
// import 'react-dropdown/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import Login from "./Login";
import RegisterPassword from "./RegisterPassword";
import Dashboard from "./Dashboard/index";
import DiscountCoupon from "./DiscountCoupon/index";
import GiftCard from "./GiftCard/index";
import AddDiscount from "./components/AddDiscount/index";
import EditDiscount from "./components/EditDiscount/index";
import AddGiftCard from "./components/AddGiftCard/index";
import EditGiftCard from "./components/EditGiftCard/index";
import SelectUser from "./components/SelectUser/index";
import ChangePassword from './ChangePassword';
// import { connect, useDispatch } from 'react-redux';
import './I10n/i18n'
import ProtectedRoute from "./ProtectedRoute";

function App(props) {
  // const [default_language, setDefaultLanguage] = useState('pt');
  // const dispatch = useDispatch()

  // useEffect(async () => {
  //   // props.toggleLoader(true);
  //   // var auth_me = await userLoggedIn()
  //   // dispatch(update(auth_me))
  //   if (localStorage.getItem('langauge_selected') !== undefined && localStorage.getItem('langauge_selected') !== null) {
  //     setDefaultLanguage(localStorage.getItem('langauge_selected'));
  //     // props.setLocaleWithFallback(localStorage.getItem('langauge_selected'));
  //   } else {
  //     setDefaultLanguage("pt");
  //     // props.setLocaleWithFallback("pt");
  //     localStorage.setItem('langauge_selected', "pt");
  //   }
  //   document.dir = props.locale === "en" ? "en" : "pt";
  // }, [props.locale])
  return (
    <>

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<RegisterPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route exact path='/discount-coupon' element={<ProtectedRoute />}>
          <Route path="/discount-coupon" element={<DiscountCoupon />} />
        </Route>
        <Route exact path='/gift-card' element={<ProtectedRoute />}>
          <Route path="/gift-card" element={<GiftCard />} />
        </Route>
        <Route exact path='/add-coupon' element={<ProtectedRoute />}>
          <Route path="/add-coupon" element={<AddDiscount />} />
        </Route>
        <Route exact path='/edit-coupon/:id' element={<ProtectedRoute />}>
          <Route path="/edit-coupon/:id" element={<EditDiscount />} />
        </Route>
        <Route exact path='/edit-giftcard/:id' element={<ProtectedRoute />}>
          <Route path="/edit-giftcard/:id" element={<EditGiftCard />} />
        </Route>
        <Route exact path='/add-giftcard' element={<ProtectedRoute />}>
          <Route path="/add-giftcard" element={<AddGiftCard />} />
        </Route>
        <Route exact path='/select-user' element={<ProtectedRoute />}>
          <Route path="/select-user" element={<SelectUser />} />
        </Route>
        <Route exact path='/change-password' element={<ProtectedRoute />}>
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>

      </Routes>
    </>
  );
}
export default App;
// const mapDispatchToProps = {
//   // getUserData,
//   // toggleLoader,
//   // setLocaleWithFallback
// }
// const mapStateToProps = (state) => {
//   return {
//     // userdata: state.userdata,
//     // locale: state.i18n.locale
//   }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(App);