import React, { useState, useEffect } from "react";
import './Card.css';
import { Table, thead, Tbody, tr, th, td } from 'react-super-responsive-table';

const Card = () => {

	return (
		<>
			<div className="CardA">
				<div class="header-body">
					{/* <div className="row mb-4">
						<h5 className="heading ml-3">Dashboard</h5>
						<div className="icona ml-3">
							<i class="fas fa-home"></i>
						</div>
						<h5 className="heading ml-3">Components</h5>
					</div> */}
					{/* boxes */}
					<div className="row">
						<div className="col-lg-3 col-md-6 mb-4">
							<div className="card border-radius-10 shadow h-100">
								<div className="card-body">
									<div className="row no-gutters align-items-center">
										<div className="col mr-2">
											<div className=" text-lg mb-1">
												Total Users
											</div>
										</div>
									</div>
									<div className=" text-lg font-weight-bold mb-1" style={{ color: "#32325d" }}>
										33
									</div>
								</div>
							</div>
						</div>

						<div className="col-xl-3 col-md-6 mb-4">
							<div className="card border-radius-10 shadow h-100">
								<div className="card-body">
									<div className="row no-gutters align-items-center">
										<div className="col mr-2">
											<div className=" text-lg mb-1">
												Total Offers
											</div>
										</div>
									</div>
									<div className=" text-lg font-weight-bold mb-1" style={{ color: "#32325d" }}>
										7
									</div>
								</div>
							</div>
						</div>

						<div className="col-xl-3 col-md-6 mb-4">
							<div className="card border-radius-10 shadow h-100">
								<div className="card-body">
									<div className="row no-gutters align-items-center">
										<div className="col mr-2">
											<div className=" text-lg mb-1">
												Active Offers
											</div>
										</div>
									</div>
									<div className=" text-lg font-weight-bold mb-1" style={{ color: "#32325d" }}>
										7
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-md-6 mb-4">
							<div className="card border-radius-10 shadow h-100">
								<div className="card-body">
									<div className="row no-gutters align-items-center">
										<div className="col mr-2">
											<div className=" text-lg mb-1">
												Total Purchases
											</div>
										</div>
									</div>
									<div className=" text-lg font-weight-bold mb-1" style={{ color: "#32325d" }}>
										14
									</div>
								</div>
							</div>
						</div>
					</div>
					{/*  */}

					<div className="col-xl-12">
						<div className="card border-radius-10 shadow h-100">
							<div className="card-body">


								<h5 className="heading1 mb-0">Recent Purchases List</h5>
								{/* <br /> */}
								<div className=" mb-4">
									<div className="col-md-12">
										<div className="card m-overflow-h" style={{ border: "none" }}>
											<div className="card-body m-p-0" >
												<div className="table-responsive">
													<Table className="table align-items-center" >
														<thead>
															<th>{('Coupon Code')}</th>
															<th>{('Discount Percentage')}</th>
															<th>{('Coupon Value')}</th>
															<th>{('Quantity Limit Actions')}</th>
															<th>{('Start Date')}</th>
															<th>{('End Date')}</th>
														</thead>
														<tbody>
															<tr>
																<td><h6>No Recent Lists.</h6></td>

															</tr>
														</tbody>
													</Table>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
}

export default Card