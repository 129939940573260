import React from "react";
import img from '../images/side-logo.png';
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
    const { t } = useTranslation();

    return (
        <>
            <ul
                className="navbar-nav bg-gradient-light sidebar sidebar-dark accordion d-none d-sm-block"
                id="accordionSidebar">
                <a className="sidebar-brand d-flex align-items-center justify-content-center">
                    <img src={img} />
                </a>
                <hr className="sidebar-divider my-0" />

                {/* <li className={(window.location.pathname === '/dashboard') ? 'nav-item active' : 'nav-item'}>
                    <NavLink className="nav-link" to={'/dashboard'}>
                        <i class="fas fa-home"></i>
                        <span>{('Dashboard')}</span>
                    </NavLink>
                </li> */}
                <li className={(window.location.pathname === '/discount-coupon') || (window.location.pathname === '/add-coupon') || (window.location.pathname === '/edit-coupon') ? 'nav-item active' : 'nav-item'}>
                    <NavLink className="nav-link" to={'/discount-coupon'}>
                        <i className="fas fa-fw fa-table" />
                        <span>{t('Discount Coupon')}</span>
                    </NavLink>
                </li>
                <li className={(window.location.pathname === '/gift-card') || (window.location.pathname === '/add-giftcard') || (window.location.pathname === '/edit-gift') ? 'nav-item active' : 'nav-item'}>
                    <NavLink className="nav-link" to={'/gift-card'}>
                        <i class="fas fa-users-cog"></i>
                        <span>{t('Gift Card')}</span>
                    </NavLink>
                </li>.
                <li className={(window.location.pathname === '/Change-password') ? 'nav-item active' : 'nav-item'}>
                    <NavLink className="nav-link" to={'/change-password'}>
                        <i class="fas fa-key"></i>
                        <span>{t('Change Password')}</span>
                    </NavLink>
                </li>
                <hr className="sidebar-divider d-none d-md-block" />
                <div className="text-center d-none d-md-inline">
                    <button className="rounded-circle border-0" id="sidebarToggle" />
                </div>
            </ul>
        </>
    )
}
export default Sidebar;