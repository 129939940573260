import React, { useState, useEffect } from "react";
// import './GiftCard.css';
import Sidebar from "../../components/Sidebar/index";
import Topbar from "../../components/Topbar/index";
import { Table, thead, tbody, tr, th, td } from 'react-super-responsive-table';
import { Link } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import { displayError, displaySuccess, getCustomerList, getProcessList, getExistingCustomerList } from "../../Utils/utils";
// import Dropdown from 'react-dropdown';
const GiftCard = (props) => {
	const [is_delete, setIsDelete] = useState(false);
	const [temp_id, setTempId] = useState("");
	const [customerList, setCustomerList] = useState([]);
	const [customer, setCustomer] = useState('');
	const [dropvalue, setDropValue] = useState('');
	const [pageCount, setPageCount] = useState(1);
	const [processList, setProcessList] = useState([]);
	const [current_page, setCurrentPage] = useState(1);


	const onSelecteditem = async (id) => {
		var var_process = await getProcessList(id.value);
		setProcessList(var_process.data)
	};

	const getCustomer = async () => {
		var cust_list_var = await getExistingCustomerList();
		var temp_arr = []
		cust_list_var.map((list) => (
			temp_arr.push({ value: list.customer_id, label: list.user_customer.name })
		))
		setCustomerList(temp_arr)
	}

	useEffect(() => {
		getCustomer();
		getProcess();
	}, [pageCount])


	const getProcess = async () => {
		// setIsloading(true)
		var var_process = await getProcessList(current_page);
		setProcessList(var_process.data)
		setPageCount(var_process.last_page)
		// setIsloading(false)
	}

	const CapitalizeFirst = str => {
		return str.charAt(0).toUpperCase() + str.slice(1)
	};
	return (
		<>
			<div id="wrapper">
				<Sidebar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<Topbar title={'Select User'} />
						<div className="container-fluid">
							<div className="col-xl-12">
								<div className="card border-radius-10 shadow h-100 py-2" style={{ borderRadius: '15px' }}>
									<div className="card-body">
										<div className="row">
											{/* <h5 className="heading1 mb-0">Select User</h5> */}
											<div className="btntop mr-5">
												{/* <Dropdown
													placeholder="Select user"
													options={customerList}
													onChange={onSelecteditem}
												/> */}
											</div>
										</div>

										<div className="deals mb-4 mt-3">
											<div className="col-md-12">
												<div className="card m-overflow-h" style={{ border: "none" }}>

													<div className="card-body m-p-0" >
														<div className="table-responsive">
															<Table className="table align-items-center" >
																<thead>
																	<th>{('Name')}</th>
																	{/* <th>{('Process Id')}</th>*/}
																	<th>{('Actions')}</th>
																</thead>
																<tbody>

																	{processList.map((list) => (
																		<tr>
																			<td>{CapitalizeFirst(list.name)}</td>
																			{/* <td>{(list.id)}	</td>*/}
																			<td>
																				<div className="row">
																					<Link to="/edit-giftcard">
																						<div className="items-center mr-1 btn-outline-secondary btn btn-sm mr-1" href>
																							<i class="far fa-edit"></i>
																						</div>
																					</Link>
																					<div className="items-center mr-1 btn-outline-danger btn btn-sm ml-1"
																						onClick={() => {
																							setIsDelete(true)
																							// setTempId(list.customer_id)
																						}}><i class="far fa-trash-alt" ></i>
																					</div>
																				</div>
																			</td>
																		</tr>
																	))}
																</tbody>
															</Table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{is_delete === true ? (
				<SweetAlert
					warning
					showCancel
					confirmBtnText="Yes"
					confirmBtnBsStyle="danger"
					title={('Are you sure?')}
					onConfirm={(e) => {
						// deleteProduct(temp_id)
						setIsDelete(false)
						window.location.reload(false)
					}}
					onClick={() => {
						setIsDelete(true)
						setTempId('')
					}}
					onCancel={(e) => {
						setIsDelete(false)
					}}
					focusCancelBtn
				>
					Do you want to delete this List?
				</SweetAlert>
			) : ([]
			)}
		</>
	);
}
export default GiftCard; 