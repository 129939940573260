import React from "react";
import './Dashboard.css';
import Sidebar from "../components/Sidebar/index";
import Topbar from "../components/Topbar/index";
import Card from "../components/Card/index";
// import Navs from "../components/TopNav/TopNav";

const Dashboard = () => {


  return (
    <>
      <div id="wrapper">
        {/* <Navs title={'Dashboard'} /> */}
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">           
            <Topbar title={'Dashboard'} />
            <div className="container-fluid">
              <Card />
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default Dashboard;