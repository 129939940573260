import React, { useState } from "react";
// import './CouponList.css';
import Sidebar from "../Sidebar/index";
import Topbar from "../Topbar/index";
import { Table, thead, Tbody, th, tr, td } from 'react-super-responsive-table';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DatePicker from "react-datepicker";
import { displayError, displaySuccess } from "../../Utils/utils";
import axios from 'axios';
import config from "../../config/config";
import { useTranslation } from "react-i18next";

const AddDiscount = (props) => {
	const [startdate, setStartDate] = useState(new Date());
	const [couponcode, setCouponCode] = useState('');
	const [discountpercentage, setDiscountPercentage] = useState('');
	const [value, setValue] = useState('');
	const [quantity, setQuantity] = useState('');
	const [enddate, setEndDate] = useState(new Date());
	const [type, setType] = useState('');
	const [is_disable, setIsDisable] = useState(false);
	const [isPercentDisable, setIsPercentDisable] = useState(false);
	const { t } = useTranslation();

	const onAddDiscount = async (id) => {
		var token = localStorage.getItem('token');
		let path = config.siteUrl;
		await axios.post(path + '/auth/admin/coupon/store', {
			start_date: startdate,
			code: couponcode,
			percentage: discountpercentage,
			end_date: enddate,
			amount: value,
			quantity: quantity,
			type: type
		}, {
			headers: { 'content-Type': 'application/json', Authorization: `Bearer ${token}` },
		})
			.then((response) => {
				displaySuccess(t('Coupon Added Successfully'));
				setTimeout(() => window.location.href = "/discount-coupon", 3000);
			})
			.catch((error) => {
				displayError(error, t);
			});
	};
	return (
		<>
			<div id="wrapper">
				<Sidebar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<Topbar title={t('Add Coupon')} />
						<div className="container-fluid">
							<div className="col-xl-12">
								<div className="card border-radius-10 shadow h-100 py-2" style={{ borderRadius: '15px' }}>
									<div className="card-body">
										<div className="row">
											{/* <h5 className="heading1 mb-0">{t('Recent Deals List')}</h5> */}
											<div className="btntop">
												<Link to="/discount-coupon">
													<button className="buttontop mr-5">
														<i class="fas fa-long-arrow-alt-left"></i> {t("Back")}</button>
												</Link>
											</div>
										</div>
										<div className=" mb-4 mt-3">
											<div className="col-md-12">
												<div className="card m-overflow-h" style={{ border: "none" }}>
													<div className="card-body m-p-0" >
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Coupon Code')}</span>
																	<input type="text" className="form-control mt-2" placeholder={t("Code")}
																		onChange={e => setCouponCode(e.target.value)} />
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Discount Percentage')}</span>
																	<input type="number" className="form-control mt-2" placeholder={t("Discount Percentage")}
																		onChange={e => {
																			setType('percentage')
																			setDiscountPercentage(e.target.value)
																			if (e.target.value.length > 0) {
																				setIsDisable(true)
																			} else {
																				setIsDisable(false)
																			}
																		}}
																		disabled={isPercentDisable}
																	/>
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Coupon Value')}</span>
																	<input type="text" className="form-control mt-2" placeholder={t("Coupon Value")}
																		onChange={e => {
																			setType('amount')
																			setValue(e.target.value)
																			if (e.target.value.length > 0) {
																				setIsPercentDisable(true)
																			} else {
																				setIsPercentDisable(false)
																			}
																		}}
																		disabled={is_disable}
																	/>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Quantity Limit Actions')} </span>
																	<input type="number" className="form-control mt-2" placeholder={t("Quantity Limit Actions")}
																		onChange={e => setQuantity(e.target.value)} />
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Start Date')}</span><br />
																	<DatePicker className="form-control mt-2" selected={startdate} onChange={(date) => setStartDate(date)} />
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-.control-label">{t('End Date')}</span><br />
																	<DatePicker className="form-control mt-2"
																		selected={enddate}
																		onChange={(date) => setEndDate(date)}
																	/>
																</div>
															</div>
														</div>
													</div>
													<Link to="">
														<button type="button" className="btn bg-warning text-white ml-3"
															onClick={onAddDiscount}>{t('Submit')}</button>
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
}
export default AddDiscount;