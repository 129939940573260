
import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
// import "./App.css";

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const [default_language, setDefaultLanguage] = useState('pt');

  const changeLanguage = lng => {
    localStorage.setItem('langauge_selected', lng);
    setDefaultLanguage(lng)
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    if (localStorage.getItem('langauge_selected')) {
      i18n.changeLanguage(localStorage.getItem('langauge_selected'));
      setDefaultLanguage(localStorage.getItem('langauge_selected'))
    } else {
      i18n.changeLanguage('pt');
      setDefaultLanguage('pt')
    }
  }, [])
  return (
    <>
      <select className="form-control" onChange={(e) => changeLanguage(e.target.value)} value={default_language}>
        <option value="en"   >{t('English')}</option>
        <option value="pt" >{t('Português')}</option>
      </select>
    </>
  );
}

export default LanguageSwitcher;
