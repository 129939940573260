// import { connect } from 'react-redux';
// import { Redirect, Route } from 'react-router-dom';

// function ProtectedRoute(props, ...rest) {
//   var token = localStorage.getItem('token');
//   if (props.userdata.userData !== null) {
//     return (
//       <Route
//         {...props}
//       />
//     );
//   } else {
//     window.location.href = '/';
//   }


// }
// const mapDispatchToProps = {
// }
// const mapStateToProps = (state) => {
//   return {
//     userdata: state.userdata
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);


import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// import { isAuthentication } from '../../lib/utlity';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { connect } from 'react-redux';

const ProtectedRoute = (props) => {
  var token = localStorage.getItem('token');
  // localStorage.setItem('redirectUrl', window.location.pathname);
  return <Outlet />;
  // return props.userdata.userData !== null ? <Route {...props} /> : <Navigate to="/" />;
};
// const mapDispatchToProps = {
// }
// const mapStateToProps = (state) => {
//   return {
//     userdata: state.userdata
//   }
// }
export default ProtectedRoute;