import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const resources = {
  pt: {
    translation: {
      "Enter your email and password to sign in": "Digite seu e-mail e senha para entrar",
      "E-mail Address": "Endereço de e-mail",
      "Login Successful": "Login bem-sucedido",
      "Login": "Conecte-se",
      "Discount Coupon": "Cupom de desconto",
      "Recent Discount Coupon": "Cupom de desconto recente",
      "Add Coupon": "Adicionar cupom",
      "Do you want to delete this Coupon": "Deseja excluir este cupom",
      "Discount Percentage": "Porcentagem de desconto",
      "Coupon Value": "Valor do cupom",
      "Quantity Limit Actions": "Ações de limite de quantidade",
      "Start Date": "Data de início",
      "End Date": "End Date",
      "Cancel": "Cancelar",
      "Deleted Successfully": "Apagado com sucesso",
      "Coupon Added Successfully": "Cupom adicionado com sucesso",
      "Add Coupon": "Adicionar cupom",
      "Recent Deals List": "Lista de ofertas recentes",
      "Coupon Updated SuccessFully": "Cupom atualizado com sucesso",
      "Edit Coupon": "Editar cupom",
      "Code": "Código",
      "Gift Card Deleted Successfully": "Vale-presente excluído com sucesso",
      "Gift Card": "Cartão Presente",
      "Recent Gift Card": "Vale-presente recente",
      "Add Card": "Adicionar cartão",
      "Pages": "Páginas",
      "Duration": "Duração",
      "End Date": "Data final",
      "Gift card added Successfully": "Vale-presente adicionado com sucesso",
      "Add GiftCard": "Add GiftCard",
      "Pages Quantity": "Quantidade de páginas",
      "Coupon Updated Successfully": "Cupom atualizado com sucesso",
      "Edit Card": "Editar cartão",
      "Coupon Updated Successfully": "Cupom atualizado com sucesso",
      "The code field is required": "O campo código é obrigatório",
      "The type field is required": "O campo tipo é obrigatório",
      "The percentage field is required": "O campo porcentagem é obrigatório",
      "The quantity field is required": "O campo quantidade é obrigatório",
      "The pages field is required": "O campo de páginas é obrigatório",
      "The duration field is required": "O campo duração é obrigatório",
      "The storage field is required": "O campo de armazenamento é obrigatório",
      "The amount field is required": "O campo valor é obrigatório",
      "Password changed successfully": "Senha alterada com sucesso",
      "The password confirmation does not match": "The password confirmation does not match",
      "Change Password": "Mudar senha",
      "Current Password": "Senha atual",
      "New Password": "Nova Senha",
      "Confirm Password": "Confirme a Senha",
      "Old password is not correct": "A senha antiga não está correta",
      "Password": "Senha",
      "u are unauthorized to access this resource": "você não tem autorização para acessar este recurso",
      "The code has already been taken": "O código já foi obtidog",

      // dashboard
      "Storage": "Armazenar",
      "Document": "Documento",
      "Images": "Imagens",
      "Files": "arquivos",
      "Upgrade your Storage": "Atualize seu armazenamento",
      "View plan": "Ver plano",
      "Files Processing Monitor": "Monitor de processamento de arquivos",
      "Pending": "Pendente",
      "Processed": "Processado",
      "Files Processed": "Arquivos processados",
      "Files Pending": "Arquivos pendentes",
      "Presents Files": "Apresenta arquivos",
      "than last day": "do que no último dia",
      "List of process": "Lista de processos",
      "Add New": "Adicionar novo",
      "Process Name": "Nome do processo",
      "Customer Name": "nome do cliente",
      "Last Updated": "Ultima atualização",
      "prev": "anterior",
      "next": "próximo",
      "Page processing used": "Processamento de página usado",
      "Processed of ": "Processado de ",
      "Page Processing Used": "Processamento de página usado",
      "Processing Available": "Processamento disponível",
      "Get details": "Obter detalhes",
      "Creative Agency": "Agência criativa",
      "Redesign Landing Page Website for Company Profile": "Redesenhe o site da página de destino para o perfil da empresa",
      "My Process": "Meu processo",
      "Due date": "Data de Vencimento",
      "In publishing and graphic design Lorem ipsum is a placeholder text": "Em editoração e design gráfico Lorem ipsum é um espaço reservado para text",
      "View All": "Ver tudo",
      "Latest Notes": "Últimas notas",
      "See All": "ver tudo",
      "No Process": "Nenhum processo",
      "Video processing used": "Processamento de vídeo usado",
      "available": "acessível",
      "search": "procurar",
      "Total Storage": "Armazenamento total",
      "Video": "Vídeo",
      "Files": "arquivos",
      "Present Files": "Arquivos presentes",
      // SideBar
      "Dashboard": "Painel",
      "Process List": "Lista de processos",
      "Customer Management": "Gestão de Clientes",
      "Settings": "Definições",

      // TopBar
      "Logout": "Sair",

      // ProcessList
      "Customer": "Cliente",
      "Status": "Status",
      "Action": "Ação",
      "Active": "Ativo",
      "Deactive": "Desativar",

      // User List
      "User List": "Lista de usuários",
      "Filter": "Filtro",
      "Customer Only": "Apenas Cliente",
      "Team only": "Somente equipe",
      "New customer": "Novo cliente",
      "New Customer": "Novo Cliente",
      "Download": "Download",
      "Assign to process": "Atribuir ao processo",
      "Name": "Nome",
      "Phone": "Telefone",
      "No Customer": "Nenhum cliente",
      "Do you want to delete this user": "Deseja excluir este usuário",

      // Settings
      "Settings": "Definições",
      "Two Factor Authentication": "Autenticação de dois fatores'",
      "Set up google authenticator": "Configurar o autenticador do Google",
      "Set up your two factor authentication by scanning the barcode below Alternatively you can use the code": "Configure sua autenticação de dois fatores digitalizando o código de barras abaixo Alternativamente, você pode usar o código",
      "Access to the most powerfull tool in the entire design and web industry": "Acesso à ferramenta mais poderosa de toda a indústria de design e web",
      "You must set up your Google Authenticator app before continuing You will be unable to login otherwise": "Você deve configurar seu aplicativo Google Authenticator antes de continuar Você não poderá fazer login de outra forma",
      "Complete Registration": "Registro completo",
      "To scan QR code you can download authenticator apps from below links": "Para digitalizar o código QR, você pode baixar aplicativos autenticadores nos links abaixo",
      // dashboard /addnew button : Add New Process            
      "Add New Process": "Adicionar novo processo",
      "Existing User": "Nome do processoUsuário existente",
      "New User": "Novo usuário",
      "Full Name": "Nome completo",
      "Please enter your name": "Por favor, insira seu nome",
      "Email Address": "Endereço de email",
      "Please enter your email": "Por favor introduza o seu e-mail",
      "Mobile": "Móvel",
      "Please enter your contact no": "Por favor, insira seu número de contato",
      "User Type": "Tipo de usuário",
      "Select User Type": "Selecione o tipo de usuário",
      "Submit": "Enviar",

      // Process List /Actionsbutton Process : dashboard 
      "Process : ": "Processo: ",
      "Back": "De volta",
      "Login Successfull": "Login bem-sucedido",
      "Coupon Added Successfully": "Cupom adicionado com sucesso",
      "New Customer has been Added": "Novo cliente foi adicionado",
      "Coupon Applied Succesfully": "Cupom aplicado com sucesso",
      "Coupon removed successfully": "Cupom removido com sucesso",
      "Payment made successfully": "Pagamento efetuado com sucesso",
      "Note has been added": "A nota foi adicionada",
      "User has been assigned to the process": "O usuário foi atribuído ao processo",
      "Customer has been assigned to the process": "O cliente foi atribuído ao processo",
      "New process has been added": "Novo processo foi adicionado",
      "New user has been added": "Novo usuário foi adicionado",
      "Logout Successfull": "Sair com sucesso",
      "user deleted successfully": "usuário deletado com sucesso",
      "Contact has been Submited": "O contato foi enviado",
      "User Edit Successfully": "Usuário editado com sucesso",
      "Profile Picture Uploaded Successfully": "Foto do perfil enviada com sucesso",
      "File Delete Successfully": "Arquivo Excluído com Sucesso",
      "File Processed Successfully": "Arquivo processado com sucesso",
      "Password reset email has been sent": "O e-mail de redefinição de senha foi enviado",
      "Verified": "Verificado",
      "Note Deleted Successfully": "Nota excluída com sucesso",
      "Files Processed Successfully": "Arquivos processados ​​com sucesso",
      "Profile Updated Successfully": "Perfil atualizado com sucesso",
      "Profile Picture Uploaded Successfully": "Foto do perfil enviada com sucesso",
      "Transaction Initiated": "Transação Iniciada",
      "Purchase link generated successfully": "Purchase link generated successfully",
      "User registered succesfully . Please verify your email now": "Utilizador registado com sucesso. Verifique seu e-mail agora",
      "Otp verified": "OTP verificado",
      "Password has been changed": "a senha foi alterada",
      "Two factor authentication disabled": "Autenticação de dois fatores desativada",
      "Copied": "Copiado",
      "Boleto Url Copied": "URL do Boleto Copiado",
      "Two factor authentication disabled": "Autenticação de dois fatores desativada",
      "User Delete Successfully": "Usuário excluído com sucesso",
      "Email Verified successfully": "E-mail verificado com sucesso",
      "Update Profile": "Atualizar perfil",
      "User Name": "Nome do usuário",
      "PROFILE PICTURE": "FOTO DO PERFIL",
      "Transactions": "Transações",
      "User Management": "Gerenciamento de usuários",
      "Customer Management": "Gestão de Clientes",
      "Transaction List": "Lista de transações",
      "All": "Todos",
      "Card": "Cartão",
      "Boleto": "boleto",
      "Payment Id": "ID de pagamento",
      "Transaction Number": "Número da transação",
      "Payment Type": "Tipo de pagamento",
      "Paid Amount": "Quantidade paga",
      "Purchase Type": "Tipo de compra",
      "Purchase Date": "data de compra",
      "Status": "Status",
      'View Details': "Ver detalhes",
      "Transaction Details": "Detalhes da transação",
      "Storage Calculation": "Cálculo de armazenamento",
      "Quantity": "Quantidade",
      "Unit Price": "Preço unitário",
      "Discount Price": "Preço com desconto",
      "Price": "Preço",
      "Processing Pages": "Páginas de processamento",
      "Processing Videos": "Processando vídeos",
      "Additional Storage": "Armazenamento Adicional",
      "Discount": "Desconto",
      "Subtotal": "Subtotal",
      "Credit Card Rate": "Taxa de cartão de crédito",
      "Grand Total": "Total geralv",
      "Purchase Pricing": "Preço de compra",
      "Generate purchase link": "Gerar link de compra",
      "Acquire For me": "Adquira para mim",
      "Benefits": "Benefícios",
      "User": "Do utilizador",
      "Unlimited": 'Ilimitado',
      "Hours Saving": "Economia de horas",
      "Available Storage GB": "GB de armazenamento disponível",
      "Work in a collaborative way": "Trabalhe de forma colaborativa",
      "Do research in all files at the same time": "Faça pesquisas em todos os arquivos ao mesmo tempo",
      "Add Notes": "Adicionar notas",
      "Share with your customer": "Compartilhe com seu cliente",
      "Safe Acess": "Acesso seguro",
      "Checkout": "Confira",
      "Credit Card": "Cartão de crédito",
      "Card Number": 'Número do cartão',
      "Card Holder Name": "Nome do Titular",
      "CVC": "CVC",
      "Card Validity": "Validade do cartão",
      "CPF": "CPF",
      "Email Address": "Endereço de e-mail",
      "ZipCode": "Código postal",
      "First Name": "Primeiro nome",
      "Last Name": "Sobrenome",
      "Address": "Endereço",
      "Country": "País",
      "Select Country": "Selecione o pais",
      "State": "Estado",
      "Select State": 'Selecione o estado',
      "City": "Cidade",
      "Select City": "Selecione a cidade",
      "title": "título",
      "Coupon Code": "Código do cupom",
      "Apply": "Aplicar",
      "Voucher Discount": "Voucher de desconto",
      "Discounted Price": "Preço com desconto",
      "Credit card rate(+)": "Taxa de cartão de crédito (+)",
      "Purchase": "Comprar",
      "Boleto rates (+)": "Taxas de boleto (+)",
      "pending": "pendente",
      "initiated": "iniciado",
      "awaiting": "aguardando",
      "paid": "pago",
      "Link Acquire": "Adquirir link",
      "Self Purchase": "Autocompra",
      "Purchase Link": "Link de Compra",
      "Copy Boleto Url": "Copiar url do boleto",
      "Boleto Url": "url do boleto",
      "View Detail": "Ver detalhes",
      "card": "cartão",
      "Go To Page": "Vá para página",
      "Copy": "cópia de",
      "File / Video reader": "Leitor de arquivo/vídeo",
      "English": "Inglês",
      "Português": "Português",
      "Set up google authenticator": "Configurar o autenticador do Google",
      "Set up your two factor authentication by scanning the barcode below. Alternatively, you can use the code": "Configure sua autenticação de dois fatores digitalizando o código de barras abaixo. Alternativamente, você pode usar o código",
      "Access to the most powerfull tool in the entire design and web industry": "Acesso à ferramenta mais poderosa em toda a indústria de design e web",
      "You must set up your Google Authenticator app before continuing. You will be unable to login otherwise": "Você deve configurar seu aplicativo Google Authenticator antes de continuar. Caso contrário, você não conseguirá fazer login",
      " Enable Two Factor Authentication": "Ativar autenticação de dois fatores",
      "If you skip two factor authentication you might lose all your documents": "Se você pular a autenticação de dois fatores, poderá perder todos os seus documentos",
      "Complete Registration": "Registro completo",
      "Skip & GoTo Login": "Ignorar e Ir Para Login",
      "To scan QR code you can download authenticator apps from below links": "Para digitalizar o código QR, você pode baixar aplicativos autenticadores nos links abaixo",
      "One Time Password": "Senha única",
      "could not scan": "não foi possível digitalizar",
      "Go Back": "Volte",
      "Verify": "Verificar",
      "generated on your Authenticator App": "gerado em seu Aplicativo Autenticador",
      "Ensure you submit the current one because it refreshes every 30 seconds": "Certifique-se de enviar o atual porque ele é atualizado a cada 30 segundos",
      "Please enter the": "Por favor insira o",
      "The process name field is required": "O campo nome do processo é obrigatório",
      "The old user id field is required": "O campo de id do usuário antigo é obrigatório",
      "Invalid login credential": "Credencial de login inválida",
      "Please Enter Correct Otp": "Insira o OTP correto",
      "invalid token": "token inválido",
      "The user name field is required": "O campo de nome de usuário é obrigatório",
      "The user email field is required": "O campo de e-mail do usuário é obrigatório",
      "The type must be a string": "O tipo deve ser uma string",
      "The phone number must be a string": "O número de telefone deve ser uma string",
      "The description field is required": "O campo descrição é obrigatório",
      "The password field is required": "O campo de senha é necessária",
      "The old password field is required": "O campo da senha antiga é obrigatório",
      "The title field is required": "O campo título é obrigatório",
      "Name field cannot be null": "O campo de nome não pode ser nulo",
      "The process role id field is required": "O campo de ID da função do processo é obrigatório",
      "The process id field is required": "O campo id do processo é obrigatório",
      "The phone number field is required": "O campo número de telefone é obrigatório",
      "The card holder name field is required": "O campo do nome do titular do cartão é obrigatório",
      "The card number field is required": "O campo do número do cartão é obrigatório",
      "The card security code field is required": "O campo do código de segurança do cartão é obrigatório",
      "The card validity field is required": "O campo validade do cartão é obrigatório",
      "The coupon code field is required": "O campo do código do cupom é obrigatório",
      "The cpf field is required": "O campo CPF é obrigatório",
      "The first name field is required": "O campo do primeiro nome é obrigatório",
      "The last name field is required": "O campo sobrenome é obrigatório",
      "The email field is required": "O campo e-mail é obrigatório",
      "The zip code field is required": "O campo CEP é obrigatório",
      "The address field is required": "O campo endereço é obrigatório",
      "Invalid coupon code": "Código de cupom inválido",
      "The user email has already been taken": "O e-mail do usuário já foi usado",
      "New user has been updated": "O novo usuário foi atualizado",
      "WHAT WE ARE": "O QUE SOMOS",
      "A business approach to legal service": "Uma abordagem empresarial para o serviço jurídico",
      "You can count on the undivided attention of a team that prizes relationships over profits": "Você pode contar com a atenção total de uma equipe que valoriza os relacionamentos acima dos lucros",
      "Every Person Who Walks Through Our Door Is Important To Us": "Cada pessoa que entra pela nossa porta é importante para nós",
      "Committed to Helping Our Clients Succeed": "Comprometidos em ajudar nossos clientes a ter sucesso",
      "Activity Today": "Atividade hoje",
      "Notification": "Notificação",


    }
  },

};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources
  });

export default i18n;




// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';


// i18n
//   // .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: 'en',
//     debug: true,

//     interpolation: {
//       escapeValue: false,
//     }
//   });
// export default i18n;