import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import apiUrl from "../config/apiPath";
import config from "../config/config";
// import { Translate, I18n } from "react-redux-i18n"

export const displayErrorString = (error) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  toast.error(error);
}
export const displayError = (error, t) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  if (error.response.status === 422) {
    var row = [];
    Object.keys(error.response.data.errors).forEach((key, i) => {
      row.push(error.response.data.errors[key][0]);
    })
    const Msg = ({ closeToast, toastProps }) => (
      row.map((char, i) => {
        var res = char.replace(/\./g, '')
        return (
          <li>
            {t(res)}
          </li>
        );
      })
    )
    toast.error(Msg);
  } else if (error.response.status === 400 || error.response.status === 401) {
    toast.error(t(error.response.data.message));
  } else {
    toast.error(t(error.message));
  }
}
export const displaySuccess = (success) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  toast.success(success);
}


export const categoryList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/common/categories", {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      return null
    });
  return result;
}

export const verifyEmail = async (token) => {
  let path = config.siteUrl;
  //var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/verify/" + token, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then(function (response) {
      return true;
    })
    .catch(function (error) {
      return false;
    });
  return response_final;
}

export const userLoggedIn = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/me", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, 'Accept': 'application/json' },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}

export const getProcessList = async (page = 1, search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/process/process-list?page=" + page + "&search=" + search, {
  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}

export const getCustomerList = async (page = 1, search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/user/customer-list", {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      // window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}

export const getExistingCustomerList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/user/existing-customer-list", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      // window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}

export const getRolesList = async (page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/customer-list", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getEditProcess = async (slug) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/process/view-process/" + slug, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getRoles = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/view-roles", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getNotesList = async (page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/view-notes", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getEditNotes = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/view-note-details/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getEditProfile = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/profile/edit", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getEditUser = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/user/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getCouponList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/admin/coupon", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      // window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const getCouponDetail = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/admin/coupon/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      // window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const getGifts = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/admin/giftcard", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      // window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const getCardDetail = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/admin/giftcard/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      // window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}