import React, { useState } from "react";
import Sidebar from "../../components/Sidebar/index";
import Topbar from "../../components/Topbar/index";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { displayError, displaySuccess } from "../../Utils/utils";
import axios from 'axios';
import config from "../../config/config";
import { useTranslation } from "react-i18next";

const AddGiftCard = () => {
	const [startdate, setStartDate] = useState(new Date());
	const [enddate, setEndDate] = useState(new Date());
	const [couponcode, setCouponCode] = useState('');
	const [pagequantity, setPageQuantity] = useState('');
	const [quantity, setQuantity] = useState('');
	const [additionalGB, setAdditionalGB] = useState('');
	const [videohour, setVideoHour] = useState('');
	const { t } = useTranslation();

	const onAddGiftCard = async (id) => {
		var token = localStorage.getItem('token');
		let path = config.siteUrl;
		await axios.post(path + '/auth/admin/giftcard/store', {
			start_date: startdate,
			end_date: enddate,
			code: couponcode,
			pages: pagequantity,
			quantity: quantity,
			storage: additionalGB,
			duration: videohour,
		}, {
			headers: { 'content-Type': 'application/json', Authorization: `Bearer ${token}` },
		})
			.then((response) => {
				displaySuccess(t('Gift card added Successfully'));
				setTimeout(() => window.location.href = "/gift-card", 3000);
			})
			.catch((error) => {
				displayError(error, t);
			});
	};
	return (
		<>
			<div id="wrapper">
				<Sidebar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<Topbar title={t('Add GiftCard')} />
						<div className="container-fluid">
							<div className="col-xl-12">
								<div className="card border-radius-10 shadow h-100 py-2" style={{ borderRadius: '15px' }}>
									<div className="card-body">
										<div className="row">
											{/* <h5 className="heading1 mb-0">Recent Deals List</h5> */}
											<div className="btntop">
												<Link to="/gift-card">
													<button className="buttontop mr-5">
														<i class="fas fa-long-arrow-alt-left"></i> {t('Back')}</button>
												</Link>
											</div>
										</div>
										<div className=" mb-4 mt-3">
											<div className="col-md-12">
												<div className="card m-overflow-h" style={{ border: "none" }}>
													<div className="card-body m-p-0" >
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Coupon Code')}</span>
																	<input type="text" className="form-control mt-2" placeholder={t("Code")}
																		onChange={e => setCouponCode(e.target.value)} />
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Pages Quantity')}</span>
																	<input type="number" className="form-control mt-2" placeholder={("Pages Quantity")}
																		onChange={e => setPageQuantity(e.target.value)} />
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label"> {t('Duration')}</span>
																	<input type="text" className="form-control mt-2" placeholder={t('Duration')}
																		onChange={e => setVideoHour(e.target.value)} />
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Storage')} (MB) </span>
																	<input type="test" className="form-control mt-2" placeholder={t("Additional GB")}
																		onChange={e => setAdditionalGB(e.target.value)} />
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Quantity')} </span>
																	<input type="number" className="form-control mt-2" placeholder={t("Quantity Limit Actions")}
																		onChange={e => setQuantity(e.target.value)} />
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-control-label">{t('Start Date')}</span><br />
																	<DatePicker className="form-control mt-2" selected={startdate} onChange={(date) => setStartDate(date)} />
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-4">
																<div className="form-group">
																	<span className="form-.control-label">{t('End Date')}</span><br />
																	<DatePicker className="form-control mt-2"
																		selected={enddate}
																		onChange={(date) => setEndDate(date)} />
																</div>
															</div>
														</div>
													</div>
													<Link to="">
														<button type="button" className="btn bg-warning text-white ml-3"
															onClick={onAddGiftCard}
														>{t('Submit')}</button>
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
}
export default AddGiftCard;
