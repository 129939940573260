import React, { useState } from 'react';
// import "./Login.css";
import img from '../components/images/side-logo.png';
import { Link } from "react-router-dom";
import config from '../config/config';
import axios from 'axios';
import { displaySuccess, displayError } from '../Utils/utils';

const RegisterPassword = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmpassword, setConfirmPassword] = useState('');

	const RegisterEmail = async (e) => {
		e.preventDefault();
		// toggleLoader(true);
		let path = config.siteUrl;
		path = path + '/register/user';
		await axios.post(path, {
			email: email,
			name: name,
			password: password,
			password_confirmation: confirmpassword,
			is_admin: 1
		})
			.then(function (response) {
				displaySuccess('Registration Successful');
				setTimeout(() => window.location.href = "/", 3000);

			})
			.catch((error) => {
				displayError(error);
			});
	}

	return (
		<>
			<div className="registerPassword ">
				<div className="row">
					<div className="col-md-3"></div>
					<div className="col-md-6">
						<div className="container">
							<div className="row">
								<div className="col-md-8 d-flex flex-column mx-auto">
									<div className="card card-raised shadow-10 mt-5 mt-xl-5 mb-4"
										style={{ borderRadius: "12px", border: "none", background: "#EFF2FC" }}>
										<div className="card-header pb-0 text-center bg-transparent" style={{ borderBottom: "none" }}>
											<img className="mb-3" src={img} alt="..." style={{ height: "58px" }} />
											<h5 className="font-weight-bolder text-dark text-gradient">Register </h5>
										</div>
										<div className="card-body">
											<div role="form text-left">
												<span>Full Name</span>
												<input className="form-control mt-2 mb-2" type="text" name="username" placeholder="Full Name"
													onChange={e => setName(e.target.value)} />

												<span>Email Address</span>
												<input className="form-control mt-2 mb-2" type="text" name="username" placeholder="E-mail Address"
													onChange={e => setEmail(e.target.value)}
												/>

												<span>Password</span>
												<input className="form-control mt-2 mb-2" type="password" name="password" placeholder="Password"
													onChange={e => setPassword(e.target.value)} />

												<span>Confirm Password</span>
												<input className="form-control mt-2 mb-2" type="password" name="password" placeholder="Confirm Password"
													onChange={e => setConfirmPassword(e.target.value)} />
												<Link to="/">
													<span>Remember Password? Login now</span>
												</Link>
												<div className="d-flex mt-4 justify-content-between">
													<Link to="">
														<button type="button" className="btn bg-warning text-white" onClick={RegisterEmail}>REGISTER</button>
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3"></div>
				</div>
			</div >
		</>
	)
};

export default RegisterPassword;

