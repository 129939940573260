import React, { useState, useEffect } from "react";
import './GiftCard.css';
import Sidebar from "../components/Sidebar/index";
import Topbar from "../components/Topbar/index";
import { Table, thead, tbody, tr, th, td } from 'react-super-responsive-table';
import { Link } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess, getCustomerList, getGifts } from "../Utils/utils";
import { useTranslation } from "react-i18next";

const GiftCard = (props) => {
	const [is_delete, setIsDelete] = useState(false);
	const [temp_id, setTempId] = useState("");
	const [cards, setCards] = useState([]);
	const { t } = useTranslation();


	const deleteProduct = async (id) => {
		var token = localStorage.getItem('token');
		let path = config.siteUrl;
		await axios.post(path + '/auth/admin/giftcard/delete/' + id, {

		}, {
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
		})
			.then(async (response) => {
				displaySuccess(t('Gift Card Deleted Successfully'));
				getGiftCards()
			})
			.catch((error) => {
				displayError(error, t);
			});
	};

	const getGiftCards = async () => {
		var res = await getGifts()
		setCards(res)
	}

	useEffect(() => {
		getGiftCards()
	}, [])
	const CapitalizeFirst = str => {
		// console.log(str)
		return str.charAt(0).toUpperCase() + str.slice(1)
	};
	return (
		<>
			<div id="wrapper">
				<Sidebar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<Topbar title={t('Gift Card')} />
						<div className="container-fluid">
							<div className="col-xl-12">
								<div className="card border-radius-10 shadow h-100 py-2" style={{ borderRadius: '15px' }}>
									<div className="card-body">
										<div className="row">
											<h5 className="heading1 mb-0">{t('Recent Gift Card')}</h5>
											<div className="btntop">
												<Link to="/add-giftcard">
													<button className="buttontop mr-5">
														<i class="fas fa-user-plus text-white mr-2 text" ></i>{t('Add Card')}</button>
												</Link>
											</div>
										</div>

										<div className="deals mb-4 mt-3">
											<div className="col-md-12">
												<div className="card m-overflow-h" style={{ border: "none" }}>

													<div className="card-body m-p-0" >
														<div className="table-responsive">
															<Table className="table align-items-center" >
																<thead>
																	<th>{t('Coupon Code')}</th>
																	<th>{t('Pages')}</th>
																	<th>{t('Duration')}</th>
																	<th>{t('Storage')}</th>
																	<th>{t('Quantity')}</th>
																	<th>{t('Start Date')}</th>
																	<th>{t('End Date')}</th>
																	<th>{t('Action')}</th>
																</thead>
																<tbody>
																	{cards && cards.map((list) => (

																		<tr>
																			<td className="tabledata">
																				<h6>{list.code}</h6>
																				{/* <h6>(Metodo dutra)</h6> */}
																			</td>
																			<td className="tabledata">
																				<h6>{list.pages}</h6>
																				{/* <h6>(Sua empresa pode mais!!)</h6> */}
																			</td>
																			<td>{list.duration}</td>
																			<td>{list.storage}</td>
																			<td>{list.quantity}</td>
																			<td>{list.start_date}</td>
																			<td>{list.end_date}</td>
																			<td>
																				<div className="row">
																					<Link to={"/edit-giftcard/" + list.id}>
																						<div className="items-center mr-1 btn-outline-secondary btn btn-sm mr-1" href>
																							<i class="far fa-edit"></i>
																						</div>
																					</Link>
																					<div className="items-center mr-1 btn-outline-danger btn btn-sm ml-1"
																						onClick={() => {
																							setIsDelete(true)
																							setTempId(list.id)
																						}}><i class="far fa-trash-alt" ></i>
																					</div>
																				</div>
																			</td>
																		</tr>
																	))}

																</tbody>
															</Table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{is_delete === true ? (
				<SweetAlert
					warning
					showCancel
					confirmBtnText="Yes"
					confirmBtnBsStyle="danger"
					title={('Are you sure?')}
					onConfirm={(e) => {
						deleteProduct(temp_id)
						setIsDelete(false)
					}}
					onClick={() => {
						setIsDelete(true)
						setTempId('')
					}}
					onCancel={(e) => {
						setIsDelete(false)
					}}
					focusCancelBtn
				>
					Do you want to delete this gift card?
				</SweetAlert>
			) : ([]
			)}
		</>
	);
}
export default GiftCard; 