import React, { useEffect, useState } from "react";
import { displaySuccess, userLoggedIn } from "../../Utils/utils";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
const Topbar = (props) => {
    const { t } = useTranslation();
    const logout = () => {
        localStorage.removeItem('token');
        displaySuccess(t('Logout Successfull'));
        setTimeout(() => window.location.href = "/", 3000);
    }

    // console.log(props)
    return (
        <>
            <nav className="navbar navbar-expand navbar-light topbar static-top " >

                <h2 className=" d-none d-sm-block">{props.title}</h2>
                {/* Topbar Navbar */}
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown mt-3 no-arrow mx-1 d-none d-sm-block">
                        <LanguageSwitcher />
                    </li>

                    <li className=" d-none d-sm-block">
                        <button
                            className="dropdown-item mt-3 " onClick={logout}>
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                            {t('Logout')}
                        </button>

                    </li>
                </ul>
            </nav>
        </>
    )
}
export default Topbar